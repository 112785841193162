import React from 'react'
import { graphql } from 'gatsby'
import { AdStarBlock, Category, GeneralSettings } from '../../types'
import { IMenu } from '../../graphql/entities/Menu'
import TopBanner from '../../components/Banners/TopBanner/TopBanner'
import Content from '../../components/Content/Content'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import AwardsHeader from '../../components/MuddyAwards/AwardsHeader'
import AwardsMenu from '../../components/MuddyAwards/AwardsMenu'
// import * as FormStyles from '../../components/BestSchoolsAwards/Form/FormStyles.module.scss'
import * as LeaderStyles from '../../components/Leader/Leader.module.scss'
import Breadcrumb from '../../components/organisms/breadcrumb/Breadcrumb'
import PageMeta from '../../components/PageMeta/PageMeta'
import Leaderboard from '../../components/Banners/Leaderboard/Leaderboard'
import { StaticImage } from 'gatsby-plugin-image'
// import { AWARDS_COUNTY_INFO } from '../../lib/data/Awards'
// import tideDataCapture from '../../api/tide'
// import { ORDERED_CATEGORIES } from '../../lib/data/Awards'

export const query = graphql`
  query {
    wpgraphql {
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
      adStarCodes {
        id
        adslot
      }
      awardsMenu: menu(id: "Awards", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      category(id: "muddy-awards", idType: SLUG) {
        sponsoredBy
        sponsoredLogo
      }
    }
  }
`

interface AwardsWinProps {
  data: {
    wpgraphql: {
      generalSettings: GeneralSettings
      adStarCodes: AdStarBlock[]
      awardsMenu: {
        menuItems: {
          nodes: IMenu[]
        }
      }
      category: Category
    }
  }
}

const AwardsWin = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      awardsMenu: {
        menuItems: { nodes: subMenu }
      },
      category
    }
  }
}: AwardsWinProps) => {
  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Muddy Stilettos Awards 2024',
          description:
            'The Muddy Stilettos Awards are the most coveted awards for independent lifestyle businesses across 28 counties, and totally FREE to enter!',
          image:
            'https://muddystilettos.co.uk/images/awards/Awards_Badge_2024.gif'
        }}
      />
      <Header />
      <TopBanner ads={adStarCodes} pageType={`awards`} />
      <Leaderboard adSlot={'TopLB'} />
      {category && (
        <AwardsHeader category={category} generalSettings={generalSettings} />
      )}
      {subMenu && (
        <AwardsMenu subMenu={subMenu} generalSettings={generalSettings} />
      )}
      <div>
        <Content>
          <Breadcrumb
            postType={`awards`}
            postTitle={'/ Win'}
            postURI={'/awards/win'}
          />
          <h1 dangerouslySetInnerHTML={{ __html: 'Win!' }} />
          <p></p>
          <div>
            <StaticImage
              src={'../../../static/images/awards/tide-b2b.jpg'}
              alt={'Tide Business Banking'}
              layout={'constrained'}
            />
          </div>
          <p>
            Go, go, GO! THE MUDDY AWARDS 2024 are live, sponsored by Tide – and
            there&apos;s <strong>£5,000 cash</strong> up for grabs to <u>ALL</u>{' '}
            businesses entering this year.
          </p>
          <p>
            Now in its 11th year, the Muddy Awards are renowned for their
            quality. Celebrating the most exciting businesses across the Muddy
            Stilettos 28 counties, the aim has always been to get loud about the
            lifestyle businesses we love and help more entrepreneurs achieve
            their goals. So, we’re thrilled to be partnering with one of the
            leading business financial platforms in the UK,{' '}
            <strong>Tide</strong>, whose own ambition is to help companies go
            from start up to SME success.
          </p>
          <p>
            What does this mean for you? If you own a UK-based company you can
            win a lump sum of <strong>£5,000 CASH</strong> to spend on your
            business courtesy of Tide and Muddy Stilettos.
          </p>
          <p>
            Simply sign up to a FREE Tide account{' '}
            <a
              href="https://www.tide.co/offers/muddystilettosawards/"
              target="_blank"
              rel="noreferrer"
            >
              HERE
            </a>{' '}
            (it only takes minutes!) using the referral code{' '}
            <strong>MUDDYAWARDS</strong> and be in with a chance to win.
          </p>
          <p>
            Plus, fund a minimum of £50 in your new Tide business account and
            get a double entry into the prize draw!
          </p>

          <p>
            <a
              href="https://www.tide.co/offers/muddystilettosawards/"
              style={{ width: 'fit-content' }}
              target={'_blank'}
              className={LeaderStyles.Button}
              rel="noreferrer"
            >
              ENTER HERE
            </a>
          </p>

          <p>
            <strong>Need to Know</strong>
          </p>
          <ul>
            <li>
              Entrants must be aged 18+, a resident in the UK and successfully
              open a Tide Business Account with Tide Platform Limited (“Tide”)
              until 23:59 on 2nd of May 2024.{' '}
            </li>
            <li>
              Entrants must not already have an existing Tide Business Account.
              All participants acknowledge and agree that they are subject to
              the full Tide Terms governing their use of Tide Platform, which
              are available{' '}
              <a
                href="https://www.tide.co/terms/"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .{' '}
            </li>
            <li>
              The prize to be one is £5,000 and it will be credited to the
              winner’s new Tide account.{' '}
            </li>
            <li>
              Only one winner will be selected to receive the prize. The winner
              will be selected randomly by a verified electronic automated
              process. Participants will be automatically enrolled in the
              promotion once their Tide account has been opened.
            </li>
            <li>
              Participants will receive a second entry in the promotion if they
              fund their Tide Account with at least £50 until 23:59 on 2nd of
              May 2024.{' '}
            </li>
            <li>
              The winner will be contacted by Tide by 31st of May 2024 to accept
              their prize and will get credited £5000 to their Tide account by
              up to 6 weeks after the offer has been accepted.{' '}
            </li>
            <li>
              Tide reserves the right to withdraw this offer any time without
              any liability. Tide gives no warranty of any kind nor accepts any
              liability whatsoever in connection with this offer or other
              services/products or your use of it.{' '}
            </li>
            <li>
              <strong>
                Full Promotion Terms and conditions can be found{' '}
                <a
                  href="https://www.tide.co/terms/muddystilettosawards/"
                  target="_blank"
                  rel="noreferrer"
                >
                  HERE
                </a>
                . We encourage you to review them carefully.
              </strong>
            </li>
          </ul>

          {/* <p>
            Read our full Muddy Stilettos{' '}
            <a
              href="https://muddystilettos.co.uk/competitions-offers-terms-conditions/"
              target="_blank"
              rel="noreferrer"
            >
              Reader Treats Terms and Conditions here
            </a>
            .
          </p>
          <p>
            Read our full Muddy Stilettos{' '}
            <a
              href="https://muddystilettos.co.uk/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy here
            </a>
            .
          </p> */}
        </Content>
      </div>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default AwardsWin
